import React, { useState, useContext, useEffect, useRef } from 'react'
import { backendStatus } from '../../../../../../backend/backend'
import DefaultStatusModals from '../../../../../../components/modal/default-status-modals'
import DeviceFactory from '../../../../../apis/device-factory'
import { DeviceContext } from '../../manage-page'
import './tr143.css'
import InfoCard from '../../../../../../components/infocard/infocard'
import NetworksIcon from '../../../../../../components/icons/networks-icon'
import Loading from '../../../../../../components/loading/loading'
// import 'bootstrap/dist/css/bootstrap.min.css'

export default function Tr143ManagementPage() {

    const [lan, setLan] = useState(null)
    const [lanGroup, setlanGroup] = useState(null)
    const [tr143download, setCurrenttr143] = useState(null);
    const [tr143upload, setCurrenttr143up] = useState(null);
    const [lanDhcp, setLanDhcp] = useState(null)
    const [lansGate, setLansGate] = useState(null)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    
    const [resultMessage, setResultMessage] = useState('')  // Para mostrar o resultado
    const [loading, setLoading] = useState(false); 
    const device = useContext(DeviceContext)
    const lanTimer = useRef(null)
    const lanGroupTimer = useRef(null)
    const mounted = useRef(true)
    const idRfo = DeviceFactory.isRFO(device.data.model)



    const handleDownloadTest = async ()  => {
setLoading(true);
        let lan1 = []
        //JSON.stringify(lan1)
        lan1.id = 'lan-1'

        let result = await device.updateResource('tr143-download', lan1);
        if (result.status !== backendStatus.SUCCESS) {
            console.error('error fetching resource: tr143-download');
            if (mounted.current)
                lanGroupTimer.current = setTimeout(handleDownloadTest, 15000);
            return;
        }
        result = await device.apply()

    async function tryRetrieveResource(retries = 5, delay = 15000) {
        let lan2 = [];
        let result_b = await device.retrieveResource('tr143-download', lan2);

        if (result_b.status === backendStatus.SUCCESS) {
            console.log('Successfully retrieved resource: tr143-download');
            if (result_b.content) {
                setCurrenttr143(result_b.content);
                setLoading(false);
                } else {
                    console.error('Tr143DownloadData não está disponível no resultado');
                }
          
                return result_b; // Retorna o resultado caso o retrieveResource tenha sucesso
        } else {
            console.error('error fetching resource: tr143-download');

            // Verifica se ainda há tentativas disponíveis
            if (retries > 0) {
                console.log(`Retrying in ${delay / 1000} seconds... (${retries} attempts left)`);
                if (mounted.current) {
                    // Aguarda o tempo de delay antes de tentar novamente
                    await new Promise(resolve => setTimeout(resolve, delay));
                    return tryRetrieveResource(retries - 1, delay); // Chama novamente a função com menos uma tentativa
                }
            } else {
                console.error('Max retries reached, could not retrieve resource.');
                return null;
            }
        }
    }

    // Tenta executar o retrieveResource com até 5 tentativas
    let result_b = tryRetrieveResource();
         
    };

function convertBitsToMbits(bits) {
    // Converte bits para Mbits
    let mbits = bits;

    // Formata o valor com duas casas decimais e adiciona "Mbits"
    return mbits.toFixed(2) + " Mbps";
}

const gettr143Lines = () => {
    // Verifica se tr143download está definido antes de acessar suas propriedades
    if (!tr143download || !tr143download.trg_down) {
        return [{label: 'Throughput Download', value: 'Indisponível'}]; // Retorna uma mensagem padrão se os dados estiverem indisponíveis
    }
                let formatted = convertBitsToMbits(tr143download.trg_down);
    return [
        {label: 'Throughput Download', value: formatted}
    ];
}

const gettr143LinesUp = () => {

    // Verifica se tr143download está definido antes de acessar suas propriedades
    if (!tr143upload || !tr143upload.trg_up) {
        return [{label: 'Throughput Upload', value: 'Indisponível'}]; // Retorna uma mensagem padrão se os dados estiverem indisponíveis
    }
    let formatted = convertBitsToMbits(tr143upload.trg_up);
    return [
        {label: 'Throughput Upload', value: formatted}
    ];
}
    const handleUploadTest = async () => {
        setLoading(true);
        let lan1 = []
        //JSON.stringify(lan1)
        lan1.id = 'lan-1'

        let result = await device.updateResource('tr143-upload', lan1);
        if (result.status !== backendStatus.SUCCESS) {
            console.error('error fetching resource: tr143-download');
            if (mounted.current)
                lanGroupTimer.current = setTimeout(handleUploadTest, 15000);
            return;
        }
        result = await device.apply()

    async function tryRetrieveResourceUp(retries = 5, delay = 15000) {
        let lan2 = [];
        let result_b = await device.retrieveResource('tr143-upload', lan2);

        if (result_b.status === backendStatus.SUCCESS) {
            console.log('Successfully retrieved resource: tr143-upload');
            if (result_b.content) {
                setCurrenttr143up(result_b.content);
                setLoading(false);


                } else {
                    console.error('Tr143UploadData não está disponível no resultado');
                }
          
                return result_b; // Retorna o resultado caso o retrieveResource tenha sucesso
        } else {
            console.error('error fetching resource: tr143-upload');

            // Verifica se ainda há tentativas disponíveis
            if (retries > 0) {
                console.log(`Retrying in ${delay / 1000} seconds... (${retries} attempts left)`);
                if (mounted.current) {
                    // Aguarda o tempo de delay antes de tentar novamente
                    await new Promise(resolve => setTimeout(resolve, delay));
                    return tryRetrieveResourceUp(retries - 1, delay); // Chama novamente a função com menos uma tentativa
                }
            } else {
                console.error('Max retries reached, could not retrieve resource.');
                return null;
            }
        }
    }

        let result_b = tryRetrieveResourceUp();
    };

    const dismissModal = () => {
        setSuccess(false);
        setSaving(false);
        setError(false);
    };
    
    return (

        <div className='tr143-management-page'>
            <DefaultStatusModals
                success={success}
                error={error}
                saving={saving}
                continueFn={dismissModal}
            />

            <div className="container">
                <h1 className="mb-4">Teste de Download e Upload</h1>

                {/* Botão de Download */}
                <button
                    id="download-btn"
                    className="btn btn-primary btn-lg btn-custom"
                    onClick={handleDownloadTest}
                >
                    Teste de Download

                </button>
                 {loading && <Loading show={true} />} {
                        <div className='wan-card'>
                            <div className='dashboard-subtitle'>
                                <NetworksIcon size='20'></NetworksIcon>
                                <label>Resultado Download</label>
                            </div>

                            <InfoCard
                                lines={gettr143Lines()}
                            ></InfoCard>
                        </div>

                    }
                {/* Botão de Upload */}
                <button
                    id="upload-btn"
                    className="btn btn-success btn-lg btn-custom"
                    onClick={handleUploadTest}
                >
                    Teste de Upload
                </button>
                    {loading && <Loading show={true} />} {
                        <div className='wan-card'>
                            <div className='dashboard-subtitle'>
                                <NetworksIcon size='20'></NetworksIcon>
                                <label>Resultado Upload</label>
                            </div>

                            <InfoCard
                                lines={gettr143LinesUp()}
                            ></InfoCard>
                        </div>
                    }
                {/* Resultado */}
                <div className="result mt-3" id="result">
                    {resultMessage}
                </div>
            </div>
        </div>
    );
}
